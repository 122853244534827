<template>
  <v-container class="pa-0 white elevation-6 transparent rounded-t-2" fluid>
    <v-card elevation="0" :loading="loading">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        {{ $t('accounting.lang_accounting_sapSettings')}}
      </v-card-title>
      <v-card-text class="pb-4">
        {{$t('settings.lang_sapExportText')}}
        <v-form lazy-validation ref="SAPform">
          <v-row dense>
            <v-col cols="12">
              <v-switch :disabled="loading" :loading="loading" v-model="enableFTPUpload" true-value="1" false-value="0" class="mx-2" :label="$t('generic.lang_enableFTPUpload')"/>
              <v-switch :disabled="loading" :loading="loading" v-model="enableSSL" true-value="1" false-value="0" class="mx-2" :label="$t('generic.lang_useSSL')"/>
            </v-col>

            <v-col cols="12" sm="10">
              <v-text-field :disabled="loading" :loading="loading" dense :label="$t('generic.lang_ftpIpAddress')" :rules="[rules.ipAddress]" outlined v-model="ftpAddress"/>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field :disabled="loading" :loading="loading" dense :label="$t('generic.lang_ftpPort')" type="number" :min="0" outlined v-model="ftpPort"/>
            </v-col>
            <v-col cols="12" sm="6" >
              <v-text-field :disabled="loading" :loading="loading" dense :label="$t('generic.lang_ftpUser')" outlined v-model="ftpUser"/>
            </v-col>
            <v-col cols="12"  sm="6">
              <v-text-field :disabled="loading" :loading="loading" dense :type="showPass?'text':'password'" :label="$t('generic.lang_ftpPassword')" outlined v-model="ftpPassword">
                <template v-slot:append>
                  <v-icon @click="showPass=!showPass">
                    {{showPass?'mdi-eye-off':'mdi-eye'}}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="success" :disabled="loading" :loading="loading" @click="updateData">
          {{$t('generic.lang_save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from "@/config";
import validation from "../../../mixins/validation/validation";

export default {
  name: "SAPReportComponent",
  data: () => {
    return {
      enableFTPUpload:false,
      enableSSL:false,
      ftpAddress:'',
      ftpPort:0,
      ftpPassword:null,
      ftpUser:null,
      showPass:false,
      loading:false,
      valid:false,
    }
  },
  mixins:[validation],
  mounted() {
    this.getData();
  },
  methods: {
    updateData () {
      this.loading=true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.SAPSETTINGS.UPDATE, {
        sap_enableFTPUpload: this.enableFTPUpload,
        sap_enableSSL: this.enableSSL,
        sap_ftpAddress: this.ftpAddress,
        sap_ftpPort: this.ftpPort,
        sap_ftpUsername: this.ftpUser,
        sap_ftpPassword: this.ftpPassword
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      })
    },
    getData() {
      this.loading=true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DATEV.SAPSETTINGS.GET, ).then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.enableFTPUpload = res.data.sap_enableFTPUpload
          this.enableSSL = res.data.sap_enableSSL
          this.ftpAddress = res.data.sap_ftpAddress
          this.ftpPort = res.data.sap_ftpPort
          this.ftpUser = res.data.sap_ftpUsername
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
  }
}
</script>

<style scoped>
.dd-header-bg-3 {
  background-image: url('./../../../assets/images/dropdown-header/abstract1.jpg');
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}

.dropdown-menu-header {
  z-index: 0 !important;
}
</style>