<template>
    <div>
        <page-title :heading="$t('accounting.lang_accounting_sapSettings')" :subheading="$t('accounting.lang_accounting_sapSettings')" :icon=icon></page-title>
        <div class="app-main__inner">
          <SAPReportComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import SAPReportComponent from "../../../components/accounting/sapreport/SAPReportComponent";

    export default {
        components: {
          SAPReportComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-filter icon-gradient bg-tempting-azure',
        })
    }
</script>